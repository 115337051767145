import {
  ActivityStoreClassReport,
  ModuleStoreClassReport,
} from '../../interfaces/class-report.interface';
import {
  AddPreviewFilter,
  Module,
  PreviewContentUpdate,
  UpdatePreviewFilter,
} from '../../interfaces/module.interface';
import { Action } from '@ngrx/store';
import { StudentReportStore } from 'up';

export enum ModulesActions {
  LoadModules = '[Modules] Load Modules',
  UpdateActivityStatus = '[Modules] Update Activity Selection Status',
  UpdatePreviewContent = '[Modules] Update Activity Preview Content',
  AddPreviewFilters = '[Modules] Add Preview Filter',
  UpdatePreviewFilters = '[Modules] Update Preview Filter',
  AddModuleClassReport = '[Modules] Add Module Class Report',
  AddActivityClassReport = '[Modules] Add Activity Class Report',
  AddStudentReport = '[Modules] Add Student Report',
  ClearStudentReport = '[Modules] Clear Student Report',
  UpdateLastVisitedStatus = '[Modules] Update Last Visited Status ',
}

export class LoadModules implements Action {
  public readonly type = ModulesActions.LoadModules;
  constructor(public payload: Module[]) {}
}

export class UpdatePreviewContent implements Action {
  public readonly type = ModulesActions.UpdatePreviewContent;
  constructor(public payload: PreviewContentUpdate) {}
}
export class AddPreviewFilters implements Action {
  public readonly type = ModulesActions.AddPreviewFilters;
  constructor(public payload: AddPreviewFilter) {}
}
export class UpdatePreviewFilters implements Action {
  public readonly type = ModulesActions.UpdatePreviewFilters;
  constructor(public payload: UpdatePreviewFilter) {}
}

export class AddModuleClassReport implements Action {
  public readonly type = ModulesActions.AddModuleClassReport;
  constructor(public payload: ModuleStoreClassReport) {}
}

export class AddActivityClassReport implements Action {
  public readonly type = ModulesActions.AddActivityClassReport;
  constructor(public payload: ActivityStoreClassReport) {}
}

export class AddStudentReport implements Action {
  public readonly type = ModulesActions.AddStudentReport;
  constructor(public payload: StudentReportStore) {}
}

export class ClearStudentReport implements Action {
  public readonly type = ModulesActions.ClearStudentReport;
  constructor(public payload: number) {}
}

export class UpdateLastVisitedStatus implements Action {
  public readonly type = ModulesActions.UpdateLastVisitedStatus;
  constructor(public payload: number) {}
}

export type ModulesActionsUnion =
  | LoadModules
  | UpdatePreviewContent
  | AddPreviewFilters
  | UpdatePreviewFilters
  | AddModuleClassReport
  | AddActivityClassReport
  | AddStudentReport
  | ClearStudentReport
  | UpdateLastVisitedStatus;
